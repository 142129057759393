import Swiper from "swiper";
import { Navigation } from "swiper/modules";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

export default class Marlin {
  init() {
    this._header();
    this._multioptions();
    this._sliders();
    this._loadVideo();
    this._lazyLoadMulioptionVideos();
  }

  _header() {
    const headerContent = document.querySelector(".header__main");
    function hover(targetElement, className, affectedElement = null) {
      if (!targetElement) return;
      targetElement.addEventListener("mouseenter", (e) =>
        affectedElement.classList.add(className)
      );
      targetElement.addEventListener("mouseleave", (e) =>
        affectedElement.classList.remove(className)
      );
    }
    hover(document.querySelector(".nav__header"), "is-hovering", headerContent);
  }

  _multioptions() {
    const expandingBox = document.querySelector(".multioptions__hero__bx-2");
    const collapsingBox = document.querySelector(
      ".multioptions__hero__bx-1__imgbx"
    );

    if (!expandingBox || !collapsingBox) {
      return;
    }

    expandingBox.addEventListener("mouseover", (event) => {
      collapsingBox.style.width = "0";
    });

    expandingBox.addEventListener("mouseout", (event) => {
      collapsingBox.style.width = "269px";
    });
  }

  _sliders() {
    gsap.registerPlugin(ScrollTrigger);

    let slider = document.querySelectorAll(".framework-slides__slider")[0];
    let swipper;
    if (slider) {
      swipper = new Swiper(slider, {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 40,
        grabCursor: true,
        touchEventsTarget: "container",
        navigation: {
          nextEl: ".framework-slides ",
          prevEl: ".framework-slides ",
        },
      });
    }

    const build = document.querySelector("#frameworks_build");
    const compute = document.querySelector("#frameworks_compute");
    const verify = document.querySelector("#frameworks_verify");

    const buildContent = document.querySelector("#frameworks_build_content");
    const computeContent = document.querySelector(
      "#frameworks_compute_content"
    );
    const verifyContent = document.querySelector("#frameworks_verify_content");

    function removeEffects() {
      build.classList.remove("after-effect");
      compute.classList.remove("after-effect");
      verify.classList.remove("after-effect");
    }
    function slideOneOp() {
      removeEffects();
      build.classList.add("after-effect");
      buildContent.style.display = "block";

      computeContent.style.display = "none";
      verifyContent.style.display = "none";
      swipper.slideTo(0);
    }

    function slideTwoOp() {
      removeEffects();
      compute.classList.add("after-effect");
      computeContent.style.display = "block";
      buildContent.style.display = "none";
      verifyContent.style.display = "none";
      swipper.slideTo(1);
    }

    function slideThreeOp() {
      removeEffects();
      verify.classList.add("after-effect");
      verifyContent.style.display = "block";
      buildContent.style.display = "none";
      computeContent.style.display = "none";
      swipper.slideTo(2);
    }
    if (build) {
      build.addEventListener("click", (e) => {
        slideOneOp();
      });
    }
    if (compute) {
      compute.addEventListener("click", () => {
        slideTwoOp();
      });
    }
    if (verify) {
      verify.addEventListener("click", () => {
        slideThreeOp();
      });
    }
    if (swipper) {
      swipper.on("slideChange", function (event) {
        removeEffects();
        const activeIndex = event.activeIndex;
        if (activeIndex === 0) {
          slideOneOp();
        }
        if (activeIndex === 1) {
          slideTwoOp();
        }
        if (activeIndex === 2) {
          slideThreeOp();
        }
      });
    }
    if (document.querySelector("#frameworks__section")) {
      ScrollTrigger.create({
        trigger: "#frameworks__section",
        start: "bottom bottom",
        end: "bottom top",
        pin: true,
        onToggle: (self) => { },
        onUpdate: (self) => {
          const progress = Number(self.progress.toFixed(3));

          if (progress > 0 && progress < 0.3) {
            slideOneOp();
          }
          if (progress > 0.3 && progress < 0.6) {
            slideTwoOp();
          }
          if (progress > 0.6 && progress < 0.8) {
            slideThreeOp();
          }
        },
      });
    }

    // animating hero slide seperately since we want the pin animation to start
    // when the hero sections top hits the top of the viewport
    gsap.to('.hero-slide', {
      scrollTrigger: {
        trigger: '.hero-slide',
        start: 'top top',
        pin: true,
        pinSpacing: false,
      },
    });

    // animating second section seperately since we don't want the framework section's to overlap.
    // To achive this we are using start point and end point as same here because. 
    // we want the element to be pinned and the scroll to run smoothly.
    gsap.to('.panel-slide-nooverlay', {
      scrollTrigger: {
        trigger: '.panel-slide-nooverlay',
        start: "bottom bottom",
        end: "bottom bottom",
        pin: true,
      },
    });

    // animating resources section this way so that users can see the partners section
    gsap.to(".panel-slide", {
      scrollTrigger: {
        trigger: ".panel-slide",
        start: "bottom 50%",
        pin: true,
        pinSpacing: false,
      },
    });
  }

  _loadVideo() {
    const pathToVideoDirectory = 'newsite/images/video/';
    const pathToPosterDirectory = 'newsite/images/content/';

    // hero 
    const videoElementHero = document.getElementById("main-hero-video")
    const sourceElementHero = document.getElementById("main-hero-video-src")
    const desktopVideoSrcHero = pathToVideoDirectory + "Marlin_Hero_Animation.mp4"
    const mobileVideoSrcHero = pathToVideoDirectory + 'Marlin_Hero_Animation_mobile.mp4';
    const desktopPosterHero = pathToPosterDirectory + 'poster.webp'
    const mobilePosterHero = pathToPosterDirectory + 'poster-mobile.webp'

    // compute
    const videoElementCompute = document.getElementById('framework_compute_video');
    const sourceElementCompute = document.getElementById('framework_compute_source');
    const desktopVideoSrcCompute = pathToVideoDirectory + 'Compute_Animation_opt.mp4';
    const mobileVideoSrcCompute = pathToVideoDirectory + 'Compute_Mobile_opt.mp4';
    const desktopPosterCompute = pathToPosterDirectory + 'Compute_Animation.svg';
    const mobilePosterCompute = pathToPosterDirectory + 'Compute_Mobile.svg';

    // verify
    const videoElementVerify = document.getElementById('framework_verify_video');
    const sourceElementVerify = document.getElementById('framework_verify_source');
    const desktopVideoSrcVerify = pathToVideoDirectory + 'Verify_Animation_opt.mp4';
    const mobileVideoSrcVerify = pathToVideoDirectory + 'Verify_Mobile_opt.mp4';
    const desktopPosterVerify = pathToPosterDirectory + 'Verify_Animation.webp';
    const mobilePosterVerify = pathToPosterDirectory + 'Build_Mobile.webp';

    // build
    const videoElementBuild = document.getElementById('framework_build_video');
    const sourceElementBuild = document.getElementById('framework_build_source');
    const desktopVideoSrcBuild = pathToVideoDirectory + 'Build_Animation_opt.mp4';
    const mobileVideoSrcBuild = pathToVideoDirectory + 'Build_Mobile_opt.mp4';
    const desktopPosterBuild = pathToPosterDirectory + 'Build_Animation.webp';
    const mobilePosterBuild = pathToPosterDirectory + 'Build_Mobile.webp';

    function isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    function lazyLoadVideo(videoElement, sourceElement, videoSrc, poster) {
      if (!videoElement || !sourceElement) return;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            videoElement.poster = poster;
            sourceElement.src = videoSrc;
            videoElement.load();
            observer.unobserve(videoElement);
          }
        });
      }, { rootMargin: "500px" });

      observer.observe(videoElement);
    }
    function loadHeroVideoImmediately() {
      if (!videoElementHero || !sourceElementHero) return;
  
      const isHeroDesktop = window.innerWidth > 600;
      const videoSrc = isHeroDesktop ? desktopVideoSrcHero : mobileVideoSrcHero;
      const poster = isHeroDesktop ? desktopPosterHero : mobilePosterHero;
      sourceElementHero.src = videoSrc;
      videoElementHero.poster = poster;
      videoElementHero.load();
    }

    function loadVideo() {
      if (!videoElementHero || !videoElementBuild || !videoElementCompute || !videoElementVerify) {
        return;
      }

      const isDesktop = window.innerWidth > 768;
      const isHeroDesktop = window.innerWidth > 600;

      // Handle video container background for mobile iOS
      const videoContainer = document.getElementById('video-container');
      if (videoContainer) {
        if (!isHeroDesktop && isIOS()) {
          videoContainer.style.display = 'block';
          videoContainer.style.backgroundImage = "url('newsite/images/video/Marlin_Hero_Animation_mobile.mp4')";
        } else {
          videoContainer.style.display = 'none';
          videoContainer.style.backgroundImage = 'none';
        }
      }



      // Build video
      lazyLoadVideo(
        videoElementBuild,
        sourceElementBuild,
        isDesktop ? desktopVideoSrcBuild : mobileVideoSrcBuild,
        isDesktop ? desktopPosterBuild : mobilePosterBuild
      );

      // Compute video
      lazyLoadVideo(
        videoElementCompute,
        sourceElementCompute,
        isDesktop ? desktopVideoSrcCompute : mobileVideoSrcCompute,
        isDesktop ? desktopPosterCompute : mobilePosterCompute
      );

      // Verify video
      lazyLoadVideo(
        videoElementVerify,
        sourceElementVerify,
        isDesktop ? desktopVideoSrcVerify : mobileVideoSrcVerify,
        isDesktop ? desktopPosterVerify : mobilePosterVerify
      );
      loadHeroVideoImmediately();

    }

    let lastWindowWidth = window.innerWidth;

    const handleResize = () => {
      const currentWindowWidth = window.innerWidth;
      if (Math.abs(currentWindowWidth - lastWindowWidth) > 50) {
        lastWindowWidth = currentWindowWidth;
        loadVideo();
      }
    };

    function addResizeListener() {
      window.addEventListener('resize', handleResize);
    }

    function removeResizeListener() {
      window.removeEventListener('resize', handleResize);
    }

    // Add resize event listener on page load
    addResizeListener();

    // Optionally, remove the listener when navigating away or unloading the page
    window.addEventListener('beforeunload', function () {
      removeResizeListener();
    });

    // Load video initially
    loadVideo();
  }

  _lazyLoadMulioptionVideos() {
    // this function is to lazy load the videos on multioptions page
    const videos = document.querySelectorAll('[data-marlin-video]');
    
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const video = entry.target;
          const source = video.querySelector('source');
          const src = source.getAttribute('data-src');
          source.setAttribute('src', src);
          video.load();
          video.play();
          observer.unobserve(video);
        }
      });
    }, options);

    videos.forEach(video => {
      observer.observe(video);
    });
  }
}
